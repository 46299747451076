/**
 * TODO: Decouple this component:
 * This file is a React Router 4 wrapper. It has a impure component
 * because it's  highly coupled to route configs and Login and NotFoundPage Components.
 * Can it will be an library in a not far distant future?
 *
 * TODO: Write some case tests for logged and not logged case
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import reverse from 'lodash/reverse'

import { privateRoutes, publicRoutes, notLoggedRoutes } from './pathUrls'
import RouteWithTemplate from './RouteWithTemplate'
import NotFoundPage from '../pages/NotFoundPage'
import VersarPage from '../pages/VersarPage'
import { roles } from '../utils/constants'

const mapStateToProps = ({ auth }) => {
  return {
    isLogged: auth.isLogged,
    occupations: auth.currentOccupation,
    userIsContact: auth?.user?.data?.attributes?.contact_from_school
  }
}

const findDefault = (userRole = null) => {
  return privateRoutes.find(route => {
    if (route.defaultFor) {
      return route.defaultFor && route.defaultFor.includes(userRole)
    } else if (route.default) {
      return route.default
    }
  })
}

const Routes = ({ isLogged, occupations, userIsContact }) => {
  // const isAdmin = occupations?.includes('administrator')
  // const VERSAR_APP_ID = process.env.REACT_APP_PROJECT_ID === '34'

  const isContact =
    ((occupations?.includes(roles.TEACHER) && userIsContact) ||
      !occupations?.includes(roles.TEACHER)) &&
    ((occupations?.includes(roles.COORDINATOR) && userIsContact) ||
      !occupations?.includes(roles.COORDINATOR))
  const pathsUnauthorizedForNonContacts = [
    '/messages',
    '/messages/inbox',
    '/messages/inbox/:communicationId',
    '/messages/create'
  ]
  const defaultPrivateRoute = findDefault(occupations?.[0])

  const setRoute = route => {
    return route.template ? (
      <RouteWithTemplate {...route} key={route.path} exact />
    ) : (
      <Route key={route.path} {...route} exact />
    )
  }
  /**
   * This preserves the path for redirect to wished page after login
   * @param {*} route
   */
  const setPrivateRoute = route => {
    const allowedRoute =
      route.permission.filter(perm => {
        return occupations?.some(role => perm === role)
      }).length > 0
        ? isContact
          ? route
          : !pathsUnauthorizedForNonContacts.includes(route.path) && route
        : null

    if (!isLogged) {
      return <Redirect key={route.path} from={route.path} to={'/'} />
    }
    return (
      allowedRoute &&
      setRoute({
        ...allowedRoute,
        template: isLogged ? allowedRoute.template : undefined,
        component: isLogged ? allowedRoute.component : VersarPage
      })
    )
  }

  const setRedirect = route => {
    return (
      <Redirect
        key={route.path}
        from={route.path}
        to={defaultPrivateRoute.path}
        exact
      />
    )
  }

  const routesPrecedence = [
    privateRoutes.map(setPrivateRoute),
    notLoggedRoutes.map(isLogged ? setRedirect : setRoute),
    publicRoutes.map(setRoute)
  ]

  /**
   * Two precedence rules:
   * 1 - User is Logged: Private Routes > Public Routes
   * 2 - User is Not Logged: Public Routes > Not Logged Routes > Private Routes
   *
   * These two rules allow overwrites on direction of user status, easing the route configuration.
   */
  const routes = isLogged ? routesPrecedence : reverse(routesPrecedence)

  return (
    <Switch>
      {routes}
      <Route component={NotFoundPage} />
    </Switch>
  )
}

Routes.propTypes = {
  isLogged: PropTypes.bool.isRequired,
  occupations: PropTypes.array,
  userIsContact: PropTypes.bool
}

export default withRouter(connect(mapStateToProps)(Routes))
