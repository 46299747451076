import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'

import { Form, reduxForm, Field, formValueSelector, change } from 'redux-form'

import { makeStyles } from '@material-ui/core/styles'

import { roles } from '../../utils/constants'
import AsyncSelect from '../shared/AsyncSelect'
import MaterialInput from '../shared/MaterialInput'
import CustomTable from '../table/CustomTable'
import Button from '../button/Button'
import filterSVG from '../../assets/icons/filter-icon.svg'
import viewSVG from '../../assets/icons/pattern-view-icon.svg'
import editSVG from '../../assets/icons/pattern-edit-icon.svg'
import addContentSVG from '../../assets/icons/add-content.svg'
import modulePuzzleSVG from '../../assets/icons/module-puzzle.svg'
import trangleWarningSVG from '../../assets/icons/triangle-warning-red.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import themePuzzleSVG from '../../assets/icons/theme-puzzle.svg'
import ReactSelect from '../../components/select/ReactSelect'

import ConfirmModal from '../modal/ConfirmModal'
import StyledSVG from '../shared/StyledSVG'
import { Tooltip } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import {
  newGetModules,
  newGetModuleThemes,
  deleteModule,
  removeThemeFromModule
} from '../../store/modules/actions'
import AddIcon from '@material-ui/icons/Add'

import SkillsComponent from '../shared/SkillsComponent'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  actionsSection: {
    display: 'flex',
    gap: 8,
    alignItems: 'center',
    '& svg': {
      '&:hover': {
        cursor: 'pointer'
      }
    },
    '& path': {
      stroke: '#868E96'
    },
    '& circle': {
      stroke: '#868E96'
    }
  },
  pageLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 20,
    '& h1': {
      fontSize: 32,
      color: '#386093',
      fontWeight: 700,
      margin: 0
    },
    '& span': {
      color: '#555555',
      fontSize: 16
    }
  },
  projectList: {
    marginTop: 32,
    marginBottom: 16,
    fontSize: 18,
    color: '#4D5E80',
    margin: 0
  },
  searchDiv: {
    '& .MuiInputBase-root': {
      width: 300,
      [theme.breakpoints.down('426')]: {
        maxWidth: '250px'
      },
      [theme.breakpoints.down('330')]: {
        maxWidth: 220
      }
    }
  },
  filtersSection: {
    display: 'flex',
    flexDirection: 'column'
  },
  filterLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  },
  advancedFilter: {
    overflow: 'hidden',
    transition: 'height 0.5s ease-in-out',
    [theme.breakpoints.down('1000')]: {
      overflow: 'scroll'
    }
  },
  filtersDisplay: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 24
  },
  searchGroup: {
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 24,
    [theme.breakpoints.down('1000')]: {
      flexDirection: 'column',
      gap: 12
    }
  },
  searchField: {
    '& .select__menu-list': {
      maxHeight: '150px'
    },
    '& div': {
      '& .select__control': {
        minWidth: 200
      }
    },
    [theme.breakpoints.down('370')]: {
      '& div': {
        minWidth: 'unset',
        '& .select__control, .MuiInputBase-root': {
          width: '64vw !important'
        }
      }
    },
    [theme.breakpoints.down('1000')]: {
      width: '100% !important',
      '& div': {
        // redux form 'Field' componente
        '& div': {
          '& .select__control, .select__value-container, .select__indicators, .MuiInputBase-root': {
            minHeight: '35px',
            height: '35px'
          },
          maxWidth: '100%'
        }
      }
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 8,
    '& button': {
      marginRight: 0,
      width: 149
    },
    [theme.breakpoints.down('1000')]: {
      justifyContent: 'flex-start',
      marginTop: 15,
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    [theme.breakpoints.down('350')]: {
      '& button': {
        width: '170px !important'
      }
    }
  },
  clearBtn: {
    backgroundColor: '#EF7C8F',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  applyBtn: {
    backgroundColor: '#386093',
    width: 154,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  horizontalBar: {
    marginBottom: 27,
    width: '96%',
    height: 1,
    border: '1px solid #ADB8CC'
  },
  backBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  createBtn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    [theme.breakpoints.up('890')]: {
      marginTop: 16
    },
    whiteSpace: 'nowrap'
  },
  emptyChildData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    '& span': {
      color: '#868E96',
      fontWeight: 600,
      fontSize: 14
    }
  },
  moduleName: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .title': {
      color: '#868E96',
      fontWeight: 700,
      fontSize: 18
    },
    '& .description': {
      color: '#868E96',
      fontWeight: 400,
      fontSize: 14
    }
  },
  statusComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '30px',
    borderRadius: 16,
    userSelect: 'none',
    '& span': {
      color: '#FFFFFF'
    }
  },
  ongoingStatus: {
    backgroundColor: '#F4A87C'
  },
  statusUndefined: {
    backgroundColor: 'rgba(173, 184, 204)'
  },
  doneStatus: {
    backgroundColor: '#9DD2DA'
  },
  trashIcon: {
    '& svg': {
      '& path': {
        stroke: '#EF7C8F'
      }
    }
  },
  arrowIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease'
  },
  arrowRotated: {
    transform: 'rotate(180deg)'
  },
  moduleChildrens: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 8,
    '& .icon': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .label': {
      color: '#868E96',
      fontWeight: 600,
      fontSize: 14
    }
  },
  skillComponent: {
    backgroundColor: 'rgba(138, 88, 141, 0.1)',
    color: '#8A588D',
    borderRadius: '4px',
    padding: '4px 7px'
  },
  skillsSection: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: 8
  },
  statusSection: {
    display: 'flex',
    justifyContent: 'center'
  },
  selects: {
    backgroundColor: '#FFF',
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '8px',
      border: 'none'
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 45,
    [theme.breakpoints.down('890')]: {
      flexDirection: 'column-reverse',
      gap: 16
    }
  },
  bottomBtns: {
    marginTop: 50,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      '& button': {
        width: '200px !important'
      }
    },
    '& button': {
      width: 150
    }
  },
  cancelBtn: {
    width: 146,
    border: '2px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '2px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const EmptyChild = () => {
  const classes = useStyles()
  return (
    <div className={classes.emptyChildData}>
      <StyledSVG src={trangleWarningSVG} height={20} width={20} />
      <span>Ainda não há tema associado a esse módulo</span>
    </div>
  )
}

const StatusComponent = ({ status, forChildren }) => {
  const classes = useStyles()
  return (
    <div className={forChildren && classes.statusSection}>
      {!status ? (
        <div
          className={`${classes.statusComponent} ${classes.statusUndefined}`}
        >
          <span>Não definido</span>
        </div>
      ) : status === 'pending' ? (
        <div className={`${classes.statusComponent} ${classes.ongoingStatus}`}>
          <span>Em andamento</span>
        </div>
      ) : (
        <div className={`${classes.statusComponent} ${classes.doneStatus}`}>
          <span>Concluído</span>
        </div>
      )}
    </div>
  )
}

const SearchModulesForm = ({
  userOccupations,
  change,
  gradeValue,
  schoolId,
  isAdmin,
  touch,
  name,
  reset,
  schoolValue,
  getModules,
  getModuleThemes,
  deleteModule,
  modules,
  pagination,
  isFetching,
  moduleThemes,
  moduleThemesIsFetching,
  selectModuleDestination,
  currentSchool,
  removeThemeFromModule
}) => {
  const firstPageAfterSearch = React.useRef(false)

  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)

  const canEditOrDeleteModule =
    userOccupations?.includes(roles.VERSAR_ADMIN) ||
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.CONTENT_CREATOR)

  const classes = useStyles()

  const [confirmModal, setConfirmModal] = React.useState({
    open: false,
    title: null,
    id: null,
    type: null,
    parentId: null
  })

  const defaultParams = {
    'q[school_id_not_null]': !(isVersarAdmin && !schoolValue),
    'q[school_id_eq]': schoolValue || schoolId
  }
  const paramsRequest = {
    'q[title_cont]': name,
    'q[grade_id_eq]': gradeValue,
    ...(selectModuleDestination === undefined || selectModuleDestination === 1
      ? defaultParams
      : { 'q[school_id_not_null]': false })
  }

  React.useEffect(() => {
    getModules({ params: { ...paramsRequest } })
  }, [])

  const history = useHistory()
  const [openMoreFilter, setOpenMoreFilter] = React.useState(false)
  const selectNameRef = React.useRef(null)
  const selectSchoolRef = React.useRef(null)
  const selectGradeRef = React.useRef(null)
  const [currentModuleId, setCurrentModuleId] = React.useState(null)

  const handleChangeTitle = event => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      handleSearch()
    }
  }
  const handleChange = (event, input) => {
    const newValue = event ? event.value : null
    change(input.name, newValue)
  }
  const handleClearFields = () => {
    reset()
    selectGradeRef.current?.select.clearValue()
    selectNameRef.current?.select.clearValue()
    selectSchoolRef.current?.select.clearValue()
    getModules({ params: { ...defaultParams } })
    firstPageAfterSearch.current = true
  }
  const handleSearch = () => {
    getModules({ params: { ...paramsRequest } })
    firstPageAfterSearch.current = true
  }

  const columns = [
    {
      key: '',
      name: '',
      render: row => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <StyledSVG src={modulePuzzleSVG} height={24} width={24} />
          </div>
        )
      }
    },
    {
      key: 'module',
      name: 'Módulo',
      width: '40%',
      render: row => {
        return (
          <div className={classes.moduleName}>
            <span className='title'>{row.title}</span>
            <span className='description'>
              {`${
                row?.description?.length > 30
                  ? `${row.description.slice(0, 30)}...`
                  : row.description
              }`}
            </span>
          </div>
        )
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '20%',
      render: row => {
        return <StatusComponent status={row.status} />
      }
    },
    {
      key: 'grade',
      name: 'Série',
      width: '30%',
      render: row => {
        return <span style={{ fontWeight: 600 }}>{row?.grade?.name}</span>
      }
    },
    {
      key: 'actions',
      name: 'Ações',
      width: '10%',
      align: 'right',
      render: function render (row) {
        return (
          <div className={classes.actionsSection}>
            <Tooltip title='Visualizar'>
              <div onClick={() => history.push(`modules/${row.id}`)}>
                <StyledSVG src={viewSVG} width={20} height={20} />
              </div>
            </Tooltip>
            {canEditOrDeleteModule &&
              row.school_id && (
                <Tooltip title='Editar'>
                  <div
                    onClick={() => history.push(`newmodules/${row.id}/edit`)}
                  >
                    <StyledSVG src={editSVG} width={20} height={20} />
                  </div>
                </Tooltip>
              )}
            {row.school_id && (
              <Tooltip title='Adicionar tema'>
                <div
                  onClick={() =>
                    history.push(`/newmodules/${row.id}/themes/create`, {
                      from: 'searchModules',
                      title: row.title,
                      moduleId: row.id
                    })
                  }
                >
                  <StyledSVG src={addContentSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
            {((canEditOrDeleteModule &&
              schoolId &&
              +schoolId === +row.school_id) ||
              isVersarAdmin) && (
              <Tooltip title='Excluir'>
                <div
                  onClick={() => {
                    setConfirmModal({
                      id: row.id,
                      title: row.title,
                      open: true,
                      type: 'modules'
                    })
                  }}
                  className={classes.trashIcon}
                >
                  <StyledSVG src={deleteSVG} width={20} height={20} />
                </div>
              </Tooltip>
            )}
            <Tooltip title='Expandir temas'>
              <div>
                <KeyboardArrowDownIcon
                  onClick={() => {
                    if (row.id === currentModuleId) {
                      setCurrentModuleId(null)
                    } else {
                      setCurrentModuleId(row.id)
                    }
                  }}
                  className={`${classes.arrowIcon} ${
                    currentModuleId === row.id ? classes.arrowRotated : ''
                  }`}
                />
              </div>
            </Tooltip>
          </div>
        )
      }
    }
  ]
  return (
    <div className={classes.root}>
      <Form>
        <div className={classes.pageLabel}>
          <h1>Módulos</h1>
          <span>
            Explore abaixo nossos programas socioemocionais e acompanhe seus
            status atualizados.
          </span>
        </div>
        {/* Header */}
        <h2 className={classes.projectList}>Lista de projetos</h2>
        <div>
          <div className={classes.searchContainer}>
            <div className={classes.searchDiv}>
              <strong>Busca</strong>
              <Field
                style={{ marginTop: 10 }}
                component={MaterialInput}
                selectRef={selectNameRef}
                name='name'
                endBtnFunction={() => {
                  handleSearch()
                }}
                onKeyDown={handleChangeTitle}
                placeholder={'Nome do módulo'}
                type='text'
              />
            </div>
            <Button
              className={classes.createBtn}
              id='create_module'
              endIcon={<AddIcon />}
              type='button'
              onClick={() => history.push('newmodules/create')}
            >
              {`Criar módulo`}
            </Button>
          </div>
          <div className={classes.filtersSection}>
            <div className={classes.filterLabel}>
              <StyledSVG src={filterSVG} width={12} height={12} />
              <p
                onClick={() => setOpenMoreFilter(prev => !prev)}
                style={{
                  cursor: 'default',
                  fontWeight: 700,
                  fontSize: 12,
                  color: '#386093'
                }}
              >
                Filtros avançados
              </p>
              {!openMoreFilter ? (
                <KeyboardArrowDownIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              ) : (
                <KeyboardArrowUpIcon
                  onClick={() => setOpenMoreFilter(prev => !prev)}
                  style={{ height: 12, width: 12 }}
                />
              )}
            </div>
            <div
              className={classes.advancedFilter}
              style={{
                height: openMoreFilter ? '250px' : '0px'
              }}
            >
              <div className={classes.filtersDisplay}>
                <div
                  className={classes.searchGroup}
                  style={{ opacity: openMoreFilter ? '1' : '0' }}
                >
                  {isVersarAdmin && (
                    <div className={classes.searchField}>
                      <strong htmlFor='school_id'>Escola</strong>
                      <Field
                        component={AsyncSelect}
                        selectRef={selectSchoolRef}
                        defaultOptions={false}
                        id='school_id'
                        name='school_id'
                        placeholder='Digite ou selecione a escola'
                        handleAsyncChange={handleChange}
                        searchParam='q[name_cont]'
                        request={{
                          path: 'school'
                        }}
                        touch={touch}
                      />
                    </div>
                  )}
                  <div className={classes.searchField}>
                    <label htmlFor={`grade_id`}>Série</label>
                    <Field
                      component={AsyncSelect}
                      selectRef={selectGradeRef}
                      id='grade_id'
                      name='grade_id'
                      placeholder='Selecione uma série'
                      handleAsyncChange={handleChange}
                      isSearchable={false}
                      request={{
                        path: 'grade'
                        // params: schoolParams
                      }}
                      touch={touch}
                    />
                  </div>
                  {!isVersarAdmin && (
                    <div className={classes.searchField}>
                      <label htmlFor={`grade_id`}>Origem</label>
                      <Field
                        component={ReactSelect}
                        handleChange={handleChange}
                        name='school_destination'
                        placeholder='Selecione a origem do módulo'
                        defaultValue={selectModuleDestination}
                        options={[
                          {
                            label: 'Versar',
                            value: 0
                          },
                          {
                            label: currentSchool?.name,
                            value: 1
                          }
                        ]}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    opacity: openMoreFilter ? '1' : '0',
                    transition: 'opacity 0.3s ease-in-out'
                  }}
                  className={classes.buttons}
                >
                  <Button
                    onClick={() => handleClearFields()}
                    className={classes.clearBtn}
                  >
                    Limpar
                  </Button>
                  <Button
                    className={classes.applyBtn}
                    type='button'
                    onClick={() => handleSearch()}
                  >
                    Aplicar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.horizontalBar} />
        </div>
        <div>
          <CustomTable
            columns={columns}
            border={'none'}
            bodyBorder={'none'}
            headCellRadius
            headColor={'rgba(173, 184, 204, 0.3)'}
            data={modules}
            params={paramsRequest}
            fetchItems={getModules}
            isFetching={isFetching}
            pagination={pagination}
            hasChild
            getChildren={getModuleThemes}
            childs={moduleThemes}
            childrenIsFetching={moduleThemesIsFetching}
            noDataChild={<EmptyChild />}
            hiddeCellToExpandChild
            currentParentId={currentModuleId}
            setCurrentParentId={setCurrentModuleId}
            firstPageAfterSearch={firstPageAfterSearch}
            childColumns={[
              {
                key: 'name',
                name: 'Tema',
                render: row => {
                  return (
                    <div className={classes.moduleChildrens}>
                      <div className='icon'>
                        <StyledSVG
                          src={themePuzzleSVG}
                          height={16}
                          width={16}
                        />
                      </div>
                      <span className='label'>{`Tema: ${row.title}`}</span>
                    </div>
                  )
                }
              },
              {
                key: 'status',
                name: 'Status',
                render: row => {
                  return <StatusComponent status={row.status} forChildren />
                }
              },
              {
                key: 'skills',
                name: 'Habilidades',
                render: row => {
                  return <SkillsComponent skills={row.skills} />
                }
              },
              {
                key: 'actions',
                name: 'Ações',
                width: '10%',
                align: 'right',
                render: function render (row) {
                  return (
                    <div className={classes.actionsSection}>
                      <Tooltip title='Visualizar'>
                        <div onClick={() => history.push(`themes/${row.id}`)}>
                          <StyledSVG src={viewSVG} width={20} height={20} />
                        </div>
                      </Tooltip>
                      {canEditOrDeleteModule &&
                        row.school_id && (
                          <Tooltip title='Editar'>
                            <div
                              onClick={() =>
                                history.push(`newthemes/${row.id}/edit`)
                              }
                            >
                              <StyledSVG src={editSVG} width={20} height={20} />
                            </div>
                          </Tooltip>
                        )}
                      {((canEditOrDeleteModule &&
                        schoolId &&
                        +schoolId === +row.school_id) ||
                        isVersarAdmin) && (
                        <Tooltip title='Excluir'>
                          <div
                            onClick={() => {
                              setConfirmModal({
                                id: row.id,
                                title: row.title,
                                open: true,
                                type: 'themes',
                                parentId: row.theme_module_id
                              })
                            }}
                            className={classes.trashIcon}
                          >
                            <StyledSVG src={deleteSVG} width={20} height={20} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )
                }
              }
            ]}
          />
          <ConfirmModal
            title={
              confirmModal.parentId
                ? `Deseja desassociar o tema "${
                    confirmModal.title
                  }" do módulo ?`
                : `Deseja remover o módulo "${
                    confirmModal.title
                  }" da lista de projetos ?`
            }
            open={confirmModal.open}
            setOpen={value => {
              if (value) {
                setConfirmModal({ ...confirmModal, open: value })
              } else {
                setConfirmModal({
                  id: null,
                  title: '',
                  open: value,
                  parentId: null
                })
              }
            }}
            onConfirm={async () => {
              if (confirmModal.parentId) {
                removeThemeFromModule({
                  themeId: confirmModal.id,
                  moduleId: confirmModal.parentId
                })
              } else {
                deleteModule(confirmModal.id)
              }
            }}
          />
        </div>
        <div className={classes.bottomBtns}>
          <Button
            type='button'
            variant={'outlined'}
            className={classes.cancelBtn}
            onClick={() => history.push('/socioemotional')}
          >
            Voltar
          </Button>
        </div>
      </Form>
    </div>
  )
}

const mapStateToProps = (state, props) => {
  const selector = formValueSelector('searchModules')
  return {
    currentSchool: state.school.currentSchool.school,
    selectModuleDestination: selector(state, 'school_destination'),
    gradeValue: selector(state, 'grade_id'),
    name: selector(state, 'name'),
    schoolId: state.school?.currentSchool?.school?.id,
    schoolValue: selector(state, 'school_id'),
    userOccupations: state.auth.currentOccupation,
    isFetching: state.modules.isFetching,
    pagination: state.modules.pagination,
    modules: state.modules.modules,
    moduleThemes: state?.modules?.currentItem?.themes,
    moduleThemesIsFetching: state?.modules?.currentItem?.isFetching
  }
}

const mapDispatchToProps = dispatch => ({
  change: (field, data) => dispatch(change('searchModules', field, data)),
  getModules: data => dispatch(newGetModules(data)),
  getModuleThemes: data => dispatch(newGetModuleThemes(data)),
  deleteModule: data => dispatch(deleteModule(data)),
  removeThemeFromModule: data => dispatch(removeThemeFromModule(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'searchModules'
  })(SearchModulesForm)
)
