// functions
export const zipcodeUrl = zipcode => `https://viacep.com.br/ws/${zipcode}/json/`

export const alreadyFilled = value => {
  return value?.length > 0
}

// validation
export const required = value =>
  (value || value?.length) > 0 ? undefined : 'Campo Obrigatório *'

export const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Endereço de email inválido'
    : undefined

export const emailToLowerCase = value => value && value.toLowerCase()

export const maxLength = max => value =>
  value && value.length > max ? `Máx. caracteres: ${max}` : undefined

export const minLength = min => value =>
  value && value.length < min ? `Min. caracteres: ${min}` : undefined

export const validZipcode = value =>
  value && onlyNums(value).length !== 8
    ? `O campo deve ter 8 caracteres`
    : undefined

export const validPhone = value =>
  value && (onlyNums(value).length < 10 || onlyNums(value).length > 11)
    ? 'Quantidade de caracteres inválido'
    : undefined

export const validFullname = value =>
  value &&
  /^[a-zA-ZéúíóáÉÚÍÓÁèùìòàçÇÈÙÌÒÀõãñÕÃÑêûîôâÊÛÎÔÂëÿüïöäËYÜÏÖÄ\-' \s]+$/.test(
    value
  )
    ? undefined
    : 'Não são permitidos números ou caracteres especiais'

// validation of maximum character size in fields
export const validContentAbstract = maxLength(659)

export const validDescription = maxLength(308)

export const validAnnouncementTitleMsg = maxLength(82)

export const validEventName = maxLength(62)

export const validUtilName = maxLength(62)

export const validReportTitle = maxLength(36)

export const capitalize = value => {
  const names = value.toLowerCase().split(' ')

  const namesToUpperCase = names.map(name => {
    if (name.length > 2) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    }
    return name
  })
  const nameCapitalized = namesToUpperCase.join(' ')

  return nameCapitalized
}

export const onlyNums = value => value.replace(/[^\d]/g, '')

export const formatNames = value => {
  const trimmedValue = value.trim()
  const formattedValue = trimmedValue.replace(/\s{2,}/g, ' ')
  return formattedValue
}

export const normalizeCEP = (value, previousValue) => {
  if (!value) {
    return value
  }
  const nums = onlyNums(value)
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (nums.length === 5) {
      return nums + '-'
    }
  }
  if (nums.length <= 5) {
    return nums
  }
  return nums.slice(0, 5) + '-' + nums.slice(5, 8)
}

export const normalizeCPF = (value, previousValue) => {
  if (!value) {
    return value
  }
  const nums = onlyNums(value)
  if (nums.length >= 11) {
    return (
      nums.slice(0, 3) +
      '.' +
      nums.slice(3, 6) +
      '.' +
      nums.slice(6, 9) +
      '-' +
      nums.slice(9, 11)
    )
  } else {
    return nums
  }
}
export const isValidCPF = (cpf, withAlert) => {
  if (typeof cpf !== 'string') {
    if (withAlert === true) return false
    else return 'CPF inválido.'
  }
  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf.length !== 11 || !!cpf.match(/(\d)\1{10}/)) {
    if (withAlert === true) return false
    else return 'CPF inválido.'
  }
  cpf = cpf.split('')
  const validator = cpf
    .filter((digit, index, array) => index >= array.length - 2 && digit)
    .map(el => +el)
  const toValidate = pop =>
    cpf
      .filter((digit, index, array) => index < array.length - pop && digit)
      .map(el => +el)
  const rest = (count, pop) =>
    ((toValidate(pop).reduce((soma, el, i) => soma + el * (count - i), 0) *
      10) %
      11) %
    10
  if (!(rest(10, 2) !== validator[0] || rest(11, 1) !== validator[1])) {
    if (withAlert === true) {
      return true
    } else {
      return undefined
    }
  } else {
    if (withAlert === true) {
      return false
    } else {
      return 'CPF inválido.'
    }
  }
}

export const normalizePhone = value => {
  if (!value) {
    return value
  }

  const nums = onlyNums(value)
  if (nums.length < 3) {
    return '(' + nums
  }
  if (nums.length <= 7) {
    return `(${nums.slice(0, 2)}) ${nums.slice(2)}`
  }
  return `(${nums.slice(0, 2)}) ${nums.slice(2, 7)}-${nums.slice(7, 11)}`
}
